import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { commonImages as images } from './commonImages'; // Import the images object
import './Pages.scss';

function MainPage() {
  return (
    <main className='main-page'>

      <Helmet>
        <meta name="description"
          content="Модернізуйте та ремонтуйте ворота та ролети без зусиль.
          Наш веб-додаток допоможе вам знайти аксесуари, які пропонують ідеальний баланс ціни та якості.
          Почніть шукати найкращі рішення."
        />
        <meta name="keywords" 
	        content="гаражні ворота, запчастини до воріт, фурнітура для воріт, ролети, захисні ролети, управління ролетами, пульти, секційні ворота, продукція" />
        <title>OSAtechno воріта і ролет</title>
        <link rel="canonical" href="https://ua.osatechno.com/" />
      </Helmet>

      <section className="purpose-section">        
        <div className="advices-content">
          <h1>Звідки почати? <span>Якщо ви шукаєте ідеальні комплектуючі
            для ваших воріт чи ролет, маємо для вас кілька корисних порад.</span></h1>
          <p className="mb-3">
            Цей застосунок призначений допомогти 👷 користувачам 🔍 знайти ⭐ найкращі за співвідношенням ціни та якості аксесуари
                 для 🔧 модернізації, 🔨 ремонту чи 🔱 покращення різних типів воріт та ролет.
          </p>
          <h2 className="mb-3"><span>OSA - Optimal</span> Selection of Accessories</h2>
  
            <ol>
              <li><strong>Оцінка потреб: </strong> 
                Визначте, які конкретні проблеми ви хотіли б вирішити або які функції хотіли б додати до ваших воріт або ролет.
              </li>
              <li><strong>Дослідження: </strong>
                Ознайомтеся з різними типами аксесуарів, які можуть вам підійти. Ви можете оглянути розділи для побутових воріт, промислових воріт, гаражних ролет та віконно-дверних ролет.
              </li>
              <li><strong>Підбір товарів: </strong>
                Перейдіть до розділу вибраної групи товарів, що містить широкий асортимент комплектуючих для різноманітних потреб.
                Переглядайте характеристики деталей для секційних воріт чи захисних ролет. Обирайте ті, що вам підходять.
              </li>
              <li><strong>Консультація: </strong>
                Якщо ви маєте питання або потребуєте додаткової допомоги, звертайтеся до спеціалізованих монтажних компаній у вашому регіоні. Вони надають послуги з огляду, сервісу, ремонту та модернізації воріт та ролет.
              </li>
            </ol>
            <p>Незалежно від того, чи ви вирішили зробити все самостійно, чи звернутися до фахівців, ми бажаємо вам успіху у виборі та вдосконаленні вашого обладнання!</p>
        </div>
      </section>

      <section className="product-groups-section">
      <Container>
        <Row xs={1} md={2} className="g-4 justify-content-center">
          <Col xs={11} md={5}>
            <Card className="card-wrapper">
            <Card.Header className="card-header">Комплектуючі для секційних воріт</Card.Header>
            <Link to="/residential">
              <Card.Img variant="top" src={images.residentialGate916}
                srcSet={`${images.residentialGate334} 334w, ${images.residentialGate668} 668w, ${images.residentialGate916} 916w`}
                sizes="(min-width: 1200px) 525px, (min-width: 1000px) calc(11.11vw + 314px), (min-width: 780px) calc(5vw + 276px), (min-width: 640px) 505px, calc(93.75vw - 76px)"
                className="img-fluid img-thumbnail product-group-image" alt="roller gate"
                width={600} height={340}/>               
            </Link>   
              <Card.Body>
                <Card.Title><h3>Побутова серія воріт</h3></Card.Title>
                <Card.Text>Оновіть свої житлові ворота за допомогою аксесуарів найвищої якості для підвищення їх продуктивності.</Card.Text>
              </Card.Body>
              <Card.Footer>
                <Button as={Link} to="/residential" variant="outline-success">Вибрати товари з цієї групи</Button>
              </Card.Footer>
            </Card>
          </Col>
          <Col xs={11} md={5}>
            <Card className="card-wrapper">
            <Card.Header className="card-header">Комплектуючі для секційних воріт</Card.Header>
              <Link to="/industrial">
              <Card.Img variant="top" src={images.industrialGate916}
                srcSet={`${images.industrialGate334} 334w, ${images.industrialGate668} 668w, ${images.industrialGate916} 916w`}
                sizes="(min-width: 1200px) 525px, (min-width: 1000px) calc(11.11vw + 314px), (min-width: 780px) calc(5vw + 276px), (min-width: 640px) 505px, calc(93.75vw - 76px)"
                className="img-fluid img-thumbnail product-group-image" alt="roller gate"
                width={600} height={340} />                   
              </Link>
              <Card.Body>
                <Card.Title><h3>Промислова серія воріт</h3></Card.Title>
                <Card.Text>Відкрийте для себе широкий асортимент аксесуарів для промислових воріт для оптимізації їх функціональності та довговічності.</Card.Text> 
              </Card.Body>
              <Card.Footer>
                <Button as={Link} to="/industrial" variant="outline-success">Вибрати товари з цієї групи</Button>
              </Card.Footer>
            </Card>
          </Col>
          <Col xs={11} md={5}>
            <Card className="card-wrapper">
              <Card.Header className="card-header">Аксесуари для захисних ролет</Card.Header>
              <Link to="/garageroller">
              <Card.Img variant="top" src={images.garageRollet916}
                srcSet={`${images.garageRollet334} 334w, ${images.garageRollet668} 668w, ${images.garageRollet916} 916w`}
                sizes="(min-width: 1200px) 525px, (min-width: 1000px) calc(11.11vw + 314px), (min-width: 780px) calc(5vw + 276px), (min-width: 640px) 505px, calc(93.75vw - 76px)"
                className="img-fluid img-thumbnail product-group-image" alt="roller gate"
                width={600} height={340} />                   
              </Link>
              <Card.Body>
                <Card.Title><h3>Гаражний тип ролет</h3></Card.Title>
                <Card.Text>Налаштуйте свої гаражні ворота надійними та стильними аксесуарами для оптимальної функціональності.</Card.Text>
              </Card.Body>
              <Card.Footer>
                <Button as={Link} to="/garageroller" variant="outline-success">Переглянути варіанти</Button>
              </Card.Footer>
            </Card>
          </Col>
          <Col xs={11} md={5}>
            <Card className="card-wrapper">
              <Card.Header className="card-header">Аксесуари для захисних ролет</Card.Header>
              <Link to="/windowroller">
              <Card.Img variant="top" src={images.windowRollet916}
                srcSet={`${images.windowRollet334} 334w, ${images.windowRollet668} 668w, ${images.windowRollet916} 916w`}
                sizes="(min-width: 1200px) 525px, (min-width: 1000px) calc(11.11vw + 314px), (min-width: 780px) calc(5vw + 276px), (min-width: 640px) 505px, calc(93.75vw - 76px)"
                className="img-fluid img-thumbnail product-group-image" alt="roller gate"
                width={600} height={340} />                     
              </Link>
              <Card.Body>
                <Card.Title><h3>Віконно-дверний тип ролет</h3></Card.Title>
                <Card.Text>Ознайомтеся з вибором аксесуарів для підвищення функціональності та естетики ваших ролет.</Card.Text>
              </Card.Body>
              <Card.Footer>
                <Button as={Link} to="/windowroller" variant="outline-success">Переглянути варіанти</Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
      </section>

     
    </main>
  );
}
export default MainPage;